import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../HomeComponents/ui/Button';
import ThemeChanger from './ThemeChanger';
import { navLinks } from '../HomeData/navLinks';
import '../styles/tailwind-home.css';
import '../styles/global-home.css';
import { AuthContext } from '../AuthContext'; // Importing Firebase AuthContext

const Navbar = () => {
  const { currentUser } = useContext(AuthContext); // Accessing the current user from the auth context
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle menu toggle

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu open state
  };

  return (
        <nav className="py-4 bg-white backdrop-blur-sm">
    <div className="container mx-auto px-4 flex flex-wrap justify-between items-center">

      {/* Left-aligned logo */}
      <Link to="/" className="text-black">
        <img
          src="/new-logo.svg"
          alt="TaxBot Logo"
          className="h-8 w-auto"
        />
      </Link>

        {/* Toggleable navigation for mobile */}
        <div className="block lg:hidden">
          <button onClick={toggleMenu} className="flex items-center px-3 py-2 border rounded text-black border-gray-400 hover:text-gray-700 hover:border-gray-500">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 5h20v2H0V8zm0 5h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        {/* Right-aligned links and button */}
        <div className={`w-full lg:flex lg:w-auto lg:flex-row lg:items-center lg:space-x-8 ${isMenuOpen ? 'block' : 'hidden'}`}>
          {/* Home and Contact links */}
          <ul className="flex flex-col lg:flex-row lg:space-x-6 space-y-4 lg:space-y-0 text-center lg:text-left">
            <li>
              <Link to="/" className="text-black">
                Home
              </Link>
            </li>
            <li>
              <Link to="/contact" className="text-black">
                Contact
              </Link>
            </li>
          </ul>

          {/* Conditional button rendering based on login status */}
          {currentUser ? (
            <Link to="/chat"> {/* If logged in, show "Access TaxBot" */}
              <Button className="text-black flex items-center justify-center px-4 py-2 mt-4 lg:mt-0 mx-auto">
                Access TaxBot
              </Button>
            </Link>
          ) : (
            <Link to="/login"> {/* If not logged in, show "Log in" */}
              <Button className="text-black flex items-center justify-center px-4 py-2 mt-4 lg:mt-0 mx-auto">
                Log in
              </Button>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
