// src/Welcome.js

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { app } from './firebaseConfig';
import GoogleButton from 'react-google-button';

const Welcome = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/chat');
    }
  }, [currentUser, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/chat');
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  };

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-base-content mb-4">Welcome to TaxBot!</h1>
        <p className="text-lg text-base-content mb-6">Signup or login below</p>
        <div className="flex flex-col justify-center space-y-4">
          <button
            className="btn btn-primary"
            onClick={() => navigate('/login')}
          >
            Login
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => navigate('/signup')}
          >
            Signup
          </button>
          <GoogleButton
            label="Sign in with Google"
            onClick={handleGoogleSignIn} // Ensure this matches the function defined
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
