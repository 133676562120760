// Chat.js

import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { db } from './firebaseConfig';
import { AuthContext } from './AuthContext';
import Sidebar from './Sidebar';
import {
  collection,
  addDoc,
  query,
  where,
  onSnapshot,
  orderBy,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
  increment,
  setDoc,
} from 'firebase/firestore';
import checkForActiveSubscription from './utilities/SubscriptionChecker';
import MessageList from './components/MessageList';
import ChatInput from './components/ChatInput';
import DropdownMenu from './components/DropdownMenu';
import AlertMessage from './components/AlertMessage';
import Overlay from './components/Overlay';
import './styles/Chat.css';
import { MathJaxContext } from "better-react-mathjax";

const Chat = () => {
  const { currentUser, loading } = useContext(AuthContext);
  const [selectedThread, setSelectedThread] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [freeMessages, setFreeMessages] = useState(0);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [pendingBotMessage, setPendingBotMessage] = useState(null); // Added pendingBotMessage state
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const messagesEndRef = useRef(null);

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [["$", "$"]],
      displayMath: [["|", "|"]],
    },
    startup: {
      typeset: false
    }
  };

  useEffect(() => {
    const checkEmailVerification = async () => {
      if (currentUser && !currentUser.emailVerified) {
        await currentUser.reload(); // Refresh the user's data
        if (!currentUser.emailVerified) {
          navigate('/email-verification');
        }
      }
    };
    checkEmailVerification();
  }, [currentUser, navigate]);

  useEffect(() => {
    // Check if a query exists in localStorage
    const savedQuery = localStorage.getItem('userQuery');
    if (savedQuery) {
      setNewMessage(savedQuery); // Set the message state to the saved query
      localStorage.removeItem('userQuery'); // Clear the saved query from localStorage
      // Optionally, send the message automatically
      handleSendMessage();
    }
  }, []);

  // Function to toggle the dropdown menu
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    // Add when the dropdown is opened
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  // Initialize and fetch freeMessages
  const initializeAndFetchFreeMessages = async () => {
    const quotaRef = doc(db, 'quotas', currentUser.uid);
    let quotaSnap = await getDoc(quotaRef);

    if (!quotaSnap.exists()) {
      await setDoc(quotaRef, {
        uid: currentUser.uid,
        freeMessages: 10,
        quotaCreated: serverTimestamp(),
        quotaUpdated: serverTimestamp(),
      });
      quotaSnap = await getDoc(quotaRef); // Refetch after setting
    } else if (quotaSnap.data().freeMessages === undefined) {
      await updateDoc(quotaRef, {
        freeMessages: 10,
        quotaUpdated: serverTimestamp(),
      });
      quotaSnap = await getDoc(quotaRef); // Refetch after updating
    }

    setFreeMessages(quotaSnap.data().freeMessages || 0);
  };

  // UseEffect hook for initializing free messages and checking subscription
  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        // Wait for the initialization of free messages
        await initializeAndFetchFreeMessages();
        // After initialization is complete, check the subscription status
        await checkSubscription();
      }
    };
    fetchData();
  }, [currentUser]);

  const checkSubscription = async () => {
    try {
      console.log('Checking subscription for user:', currentUser.uid);
      const subscriptions = await checkForActiveSubscription(currentUser.uid);
      console.log('Subscriptions received:', subscriptions);

      const currentDate = new Date();
      const hasActive = subscriptions.some((sub) => {
        const isWithinActivePeriod =
          currentDate >= new Date(sub.startDate) &&
          currentDate <= new Date(sub.endDate);
        const isTrialing = sub.status === 'trialing';
        return isWithinActivePeriod || isTrialing; // Include trialing status as active
      });

      const hasCancelled = subscriptions.some((sub) => sub.status === 'cancelled');

      console.log('Has active subscription:', hasActive);
      console.log('Has cancelled subscription:', hasCancelled);

      setHasActiveSubscription(hasActive);

      const quotaRef = doc(db, 'quotas', currentUser.uid);
      const quotaDoc = await getDoc(quotaRef);

      let freeMessagesAvailable = quotaDoc.exists()
        ? quotaDoc.data().freeMessages
        : 0;
      console.log('Free messages available:', freeMessagesAvailable);

      if (!quotaDoc.exists() && hasCancelled) {
        // If the subscription is cancelled and there's no quota doc, create one with free messages
        await setDoc(quotaRef, {
          freeMessages: 0,
          quotaCreated: serverTimestamp(),
          quotaUpdated: serverTimestamp(),
        });
        freeMessagesAvailable = 5;
      }

      // Update free messages only if no active subscription
      if (!hasActive) {
        setFreeMessages(freeMessagesAvailable);
      }

      if (!hasActive && freeMessagesAvailable <= 0) {
        setAlertMessage(
          'You do not have an active subscription. Please subscribe to continue.'
        );
        setShowOverlay(true);
        const timer = setTimeout(() => {
          navigate('/Subscriptions');
        }, 10000); // Redirect after 10 seconds
        return () => clearTimeout(timer);
      } else {
        setShowOverlay(false);
      }
    } catch (error) {
      console.error('Error checking for active subscriptions:', error);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
    setShowOverlay(false); // Hide overlay
    navigate('/Subscriptions');
  };

  // Handle thread selection and fetching messages
  useEffect(() => {
    if (currentUser) {
      const threadsRef = collection(db, 'threads');
      const q = query(
        threadsRef,
        where('user_id', '==', currentUser.uid),
        orderBy('last_updated', 'desc')
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.size === 0) {
          createAndSelectNewThread(); // Create new thread if user has none
        } else if (
          !selectedThread ||
          !querySnapshot.docs.some((doc) => doc.id === selectedThread.id)
        ) {
          const mostRecentThread = querySnapshot.docs[0].data();
          mostRecentThread.id = querySnapshot.docs[0].id;
          setSelectedThread(mostRecentThread); // Select the most recent thread
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser, selectedThread]);

  // Modified useEffect for fetching messages to include pendingBotMessage
  useEffect(() => {
    if (selectedThread?.id) {
      const messagesRef = collection(db, 'threads', selectedThread.id, 'messages');
      const unsubscribe = onSnapshot(
        query(messagesRef, orderBy('timestamp', 'asc')),
        (querySnapshot) => {
          const firestoreMessages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMessages(firestoreMessages); // Do not include pendingBotMessage here
        }
      );
      return () => unsubscribe();
    } else {
      setMessages([]);
    }
  }, [selectedThread]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  // Function to create a new thread with a welcome message
  const createAndSelectNewThread = async () => {
    const newThreadRef = await addDoc(collection(db, 'threads'), {
      user_id: currentUser.uid,
      last_updated: serverTimestamp(),
    });
    setSelectedThread({
      id: newThreadRef.id,
      user_id: currentUser.uid,
      last_updated: new Date(),
    });
    addWelcomeMessageToThread(newThreadRef.id);
  };

  // Add a welcome message to a thread
  const addWelcomeMessageToThread = async (threadId) => {
    await addDoc(collection(db, 'threads', threadId, 'messages'), {
      text: 'Welcome! How can I assist you today?',
      sender: 'system',
      timestamp: serverTimestamp(),
    });
  };

  // Updated handleSendMessage function to handle streaming
  const handleSendMessage = async () => {
    if (newMessage.trim() && !isSending) {
      setIsSending(true);

      const userMessage = {
        text: newMessage,
        sender: 'user',
        timestamp: new Date(),
      };

      // Add the user's message to Firestore
      await addDoc(collection(db, 'threads', selectedThread.id, 'messages'), userMessage);
      setNewMessage('');

      // Check for the first user message
      const isFirstMessage =
        messages.length === 0 || messages.every((msg) => msg.sender !== 'user');

      if (!hasActiveSubscription) {
        const quotaRef = doc(db, 'quotas', currentUser.uid);
        const quotaSnap = await getDoc(quotaRef);

        if (quotaSnap.exists() && quotaSnap.data().freeMessages <= 0) {
          setAlertMessage(
            'You have reached your limit of free messages. Please subscribe to continue.'
          );
          setShowOverlay(true);
          setIsSending(false);
          return;
        }

        if (quotaSnap.exists()) {
          await updateDoc(quotaRef, {
            freeMessages: increment(-1),
            quotaUpdated: serverTimestamp(),
          });

          const updatedQuotaSnap = await getDoc(quotaRef);
          setFreeMessages(updatedQuotaSnap.data().freeMessages);
        }
      }

      // Update the thread's `last_updated` timestamp
      const threadRef = doc(db, 'threads', selectedThread.id);
      await updateDoc(threadRef, {
        last_updated: serverTimestamp(),
      });

      if (isFirstMessage) {
        try {
          const titleResponse = await fetch(
            'https://title-generator-iwqskruira-uc.a.run.app/summarize',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ message: newMessage }),
            }
          );

          if (!titleResponse.ok) {
            throw new Error(`HTTP error! status: ${titleResponse.status}`);
          }

          const titleData = await titleResponse.json();
          let title = titleData.choices[0].message.content;
          title = title.replace(/^"|"$/g, ''); // Strip quotes from the title

          // Update the thread title in Firestore
          await updateDoc(threadRef, {
            title: title,
            last_updated: serverTimestamp(),
          });
        } catch (error) {
          console.error('Title generation request failed:', error);
        }
      }

      // Fetch response from the API with streaming
      try {
        const previousMessages = messages
          .filter((msg) => msg.sender === 'user' || msg.sender === 'system')
          .map((msg) => msg.text);

        const response = await fetch('https://taxbot-api-stream-14072143234.europe-west1.run.app/ask', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            question: newMessage,
            conversation_history: previousMessages,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let done = false;
        let botMessage = {
          text: '',
          sender: 'system',
          timestamp: new Date(),
        };

        setPendingBotMessage(botMessage);

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          if (value) {
            const chunkValue = decoder.decode(value);
            console.log('Received chunk:', chunkValue); // Add this line

            botMessage.text += chunkValue;

            // Update the pending bot message
            setPendingBotMessage({ ...botMessage });
          }
        }

        // After the full message is received, save it to Firestore
        await addDoc(collection(db, 'threads', selectedThread.id, 'messages'), {
          text: botMessage.text,
          sender: 'system',
          timestamp: serverTimestamp(),
        });

        // Clear the pending bot message
        setPendingBotMessage(null);
      } catch (error) {
        console.error('Request failed:', error);
        setAlertMessage('Error: Could not send the message. Please try again.');
        setShowOverlay(true);

        // Clear the pending bot message
        setPendingBotMessage(null);
      } finally {
        setIsSending(false); // Stop sending process
      }
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Loading and user check
  if (loading) return <div>Loading...</div>;
  if (!currentUser) return null;

  return (
    <MathJaxContext config={config}>
      <div className="app-container">
        {showOverlay && <Overlay />}
        {alertMessage && (
          <AlertMessage message={alertMessage} onClose={handleCloseAlert} />
        )}
        <Sidebar
          onSelectThread={setSelectedThread}
          selectedThreadId={selectedThread?.id}
          onCreateNewThread={createAndSelectNewThread}
        />
        <div className="chat-container">
          <div className="chat-header">
          <img
            src="/new-logo.svg"   // or "/new-logo.png"
            alt="TaxBot logo"
            className="h-8 w-auto"
          />
            {!hasActiveSubscription && (
              <div className="free-messages-info">
                Free messages left: {freeMessages}
              </div>
            )}
            <DropdownMenu
              showDropdown={showDropdown}
              toggleDropdown={setShowDropdown}
              navigate={navigate}
              handleLogout={handleLogout}
            />
          </div>
          <MessageList
            messages={messages}
            isSending={isSending}
            pendingBotMessage={pendingBotMessage} // Pass pendingBotMessage to MessageList
            threadId={selectedThread?.id}
            userId={currentUser.uid}
          />
          <ChatInput
            onSendMessage={handleSendMessage}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            isSending={isSending}
          />
        </div>
      </div>
    </MathJaxContext>
  );
};

export default Chat;
