import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendEmailVerification, updateEmail, signOut } from "firebase/auth";

const EmailVerification = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [message, setMessage] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [isChangingEmail, setIsChangingEmail] = useState(false);

  const handleResendVerification = async () => {
    const user = auth.currentUser;
    try {
      await sendEmailVerification(user);
      setMessage("Verification email resent. Please check your inbox.");
    } catch (error) {
      console.error("Error resending verification email:", error);
      setMessage("Error resending verification email. Please try again later.");
    }
  };

  const handleEmailChange = async () => {
    const user = auth.currentUser;
    try {
      await updateEmail(user, newEmail);
      await sendEmailVerification(user);
      setMessage("Email updated and verification sent to new email address.");
      setIsChangingEmail(false);
      setNewEmail("");
    } catch (error) {
      console.error("Error updating email:", error);
      setMessage("Error updating email. Please try again later.");
    }
  };

  const handleCheckVerification = async () => {
    const user = auth.currentUser;
    await user.reload();
    if (user.emailVerified) {
      navigate("/chat");
    } else {
      setMessage("Email not verified yet. Please check your inbox.");
    }
  };

  const handleSignOutAndRedirect = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
      setMessage("Error signing out. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl text-gray-700 font-semibold text-center mb-4">
          Verify Your Email
        </h2>
        <p className="text-gray-700 mb-6 text-center">
          A verification email has been sent to{" "}
          <span className="font-semibold">{auth.currentUser?.email}</span>.
          Please verify your email to access the chat.
        </p>
        {message && (
          <div className="mb-4 text-center text-sm text-red-500">{message}</div>
        )}
        {isChangingEmail ? (
          <div className="mb-4">
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Enter new email"
              className="w-full p-2 border border-gray-300 rounded mb-2"
            />
            <div className="flex gap-2">
              <button
                onClick={handleEmailChange}
                className="flex-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Update Email
              </button>
              <button
                onClick={() => setIsChangingEmail(false)}
                className="flex-1 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <>
            <button
              onClick={handleResendVerification}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mb-4"
            >
              Resend Verification Email
            </button>
          </>
        )}
        <button
          onClick={handleCheckVerification}
          className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mb-2"
        >
          I've Verified My Email
        </button>
        {/* Change Email Address as a text link */}
        <div className="text-center mt-4">
          <button
            onClick={() => setIsChangingEmail(true)}
            className="text-blue-500 hover:underline text-sm"
          >
            Change email address
          </button>
        </div>
        {/* Sign in with existing account */}
        <div className="text-center mt-2">
          <button
            onClick={handleSignOutAndRedirect}
            className="text-blue-500 hover:underline text-sm"
          >
            Sign in with existing account
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
