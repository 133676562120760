// MessageList.js
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeExternalLinks from 'rehype-external-links';

import FeedbackComponent from './FeedbackComponent';
import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from 'firebase/firestore';

import '../styles/Chat.css';

/**
 * Preprocess LaTeX for block and inline math
 */
const preprocessLaTeX = (content) => {
  // Replace \[...\] with $$...$$
  const blockProcessed = content.replace(
    /\\\[(.*?)\\\]/gs,
    (_, eq) => `$$${eq}$$`
  );
  // Replace \(...\) with $...$
  return blockProcessed.replace(
    /\\\((.*?)\\\)/gs,
    (_, eq) => `$${eq}$`
  );
};

const MessageList = ({ messages, isSending, pendingBotMessage, threadId, userId }) => {
  const messagesEndRef = useRef(null);

  // Store feedback docs in local state, keyed by messageId
  const [feedbacks, setFeedbacks] = useState({});

  // The array of possible "Thinking..." states
  const thinkingStates = ['Thinking', 'Thinking.', 'Thinking..', 'Thinking...'];
  // We'll use this index to decide which text to show
  const [thinkingIndex, setThinkingIndex] = useState(0);

  /**
   * Scroll to bottom whenever messages or pendingBotMessage change
   */
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, pendingBotMessage]);

  /**
   * Fetch existing feedback docs for this thread/user
   */
  useEffect(() => {
    const fetchFeedbacks = async () => {
      if (threadId && userId) {
        const feedbacksRef = collection(db, 'feedback');
        const q = query(
          feedbacksRef,
          where('threadId', '==', threadId),
          where('userId', '==', userId)
        );
        const querySnapshot = await getDocs(q);
        const feedbacksData = {};

        querySnapshot.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          // We'll store docs even if rating is null, so we remove any check
          feedbacksData[data.messageId] = { ...data, docId: docSnapshot.id };
        });

        setFeedbacks(feedbacksData);
      }
    };

    fetchFeedbacks();
  }, [threadId, userId, messages]);

  /**
   * Animate "Thinking..." states when isSending && !pendingBotMessage
   */
  useEffect(() => {
    let intervalId;
    if (isSending && !pendingBotMessage) {
      // Start or continue rotating the "Thinking" text every 500ms
      intervalId = setInterval(() => {
        setThinkingIndex((prev) => (prev + 1) % thinkingStates.length);
      }, 300);
    } else {
      // Reset the index and clear the interval if we're not in "thinking" mode
      setThinkingIndex(0);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isSending, pendingBotMessage, thinkingStates.length]);

  /**
   * Handle user feedback submission for a given message
   */
  const handleFeedbackSubmit = async (messageId, feedbackData) => {
    console.log(`Feedback for message ${messageId}:`, feedbackData);

    // 1) Build a feedbackDoc with relevant info
    const feedbackDoc = {
      threadId,
      userId,
      messageId,
      messageText: messages.find((msg) => msg.id === messageId)?.text || '',
      timestamp: new Date(),
    };

    // If rating is explicitly defined (could be 'up', 'down', or null), store it
    if (typeof feedbackData.rating !== 'undefined') {
      feedbackDoc.rating = feedbackData.rating;
    }
    // If there's free-text feedback
    if (feedbackData.feedback) {
      feedbackDoc.feedback = feedbackData.feedback;
    }

    // 2) Check if there's already a docId for this message
    const existingDocId = feedbacks[messageId]?.docId;
    if (existingDocId) {
      // Update existing doc
      const docRef = doc(db, 'feedback', existingDocId);
      await updateDoc(docRef, feedbackDoc);
    } else {
      // Create a new feedback doc
      const docRef = await addDoc(collection(db, 'feedback'), feedbackDoc);
      feedbackDoc.docId = docRef.id;
    }

    // 3) Store/update the docId in local state
    setFeedbacks((prev) => ({
      ...prev,
      [messageId]: feedbackDoc,
    }));
  };

  return (
    <div className="chat-messages">
      {messages.map((message, index) => (
        <div
          key={message.id || index}
          className={`message ${message.sender === 'user' ? 'user' : 'system'}`}
        >
          {/* Render message text using ReactMarkdown */}
          <ReactMarkdown
            children={preprocessLaTeX(message.text)}
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[
              rehypeKatex,
              [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
            ]}
            components={{
              a: ({ node, ...props }) => (
                <a className="text-blue-500 hover:underline" {...props} />
              ),
              p: ({ node, ...props }) => <p {...props} />,
            }}
          />

          {/**
           * Show feedback only if:
           * 1) This is a system message, AND
           * 2) It's NOT the first message => index !== 0
           */}
          {message.sender === 'system' && index !== 0 && (
            <FeedbackComponent
              onSubmitFeedback={(feedbackData) =>
                handleFeedbackSubmit(message.id, feedbackData)
              }
              existingRating={feedbacks[message.id]?.rating || null}
            />
          )}
        </div>
      ))}

      {/* Render any pendingBotMessage */}
      {pendingBotMessage && (
        <div className="message system">
          <ReactMarkdown
            children={preprocessLaTeX(pendingBotMessage.text)}
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={{
              a: ({ node, ...props }) => (
                <a className="text-blue-500 hover:underline" {...props} />
              ),
              p: ({ node, ...props }) => <p {...props} />,
            }}
          />
        </div>
      )}

      {/* Rotate through "Thinking." / "Thinking.." / "Thinking..." */}
      {isSending && !pendingBotMessage && (
        <div className="message system">
          <p>{thinkingStates[thinkingIndex]}</p>
        </div>
      )}

      {/* Dummy div to scroll into view at the bottom */}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;
