import React, { useState, useContext, useEffect } from "react";
import StepOne from "./StepOne";
import { createUser } from "./SignupProcessor";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import "../styles/style.css";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from "../firebaseConfig";
import GoogleButton from "react-google-button";

const Signup = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    if (currentUser) {
      navigate("/chat");
    }
  }, [currentUser, navigate]);

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const goBack = () => {
    navigate("/");
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (
        !Object.values(formData).every((value) =>
          typeof value === "string" ? value.trim() !== "" : true
        ) ||
        !formData.aiConfirmation
      ) {
        throw new Error("Please fill out all fields and accept the terms of service.");
      }

      await createUser(formData.email, formData.password, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        freeMessages: 10,
        createdAt: new Date().toISOString(),
      });

      navigate("/chat");
    } catch (error) {
      console.error("Signup error:", error);
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleSignup = async () => {
    if (!formData.aiConfirmation) {
      alert("Please fill out all fields and accept the terms of service.");
      return;
    }

    try {
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      await createUser(user.email, null, {
        firstName: user.displayName ? user.displayName.split(" ")[0] : "",
        lastName: user.displayName
          ? user.displayName.split(" ").slice(1).join(" ")
          : "",
        email: user.email,
        googleId: user.uid,
        freeMessages: 0,
        createdAt: new Date().toISOString(),
      });

      navigate("/chat");
    } catch (error) {
      console.error("Google signup error:", error);
      alert(error.message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  if (isSubmitting) {
    return (
      <div className="min-h-screen bg-base-200 flex items-center justify-center">
        <div className="card w-96 bg-base-100 shadow-xl">
          <div className="card-body items-center text-center">
            <h2 className="card-title text-base-content">
              Creating your account...
            </h2>
            <progress className="progress w-56"></progress>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body relative">
          <div className="flex items-center justify-center relative mb-2">
            <button
              className="btn btn-circle btn-ghost absolute -left-6 text-base-content"
              onClick={goBack}
            >
              ←
            </button>
            <h2 className="card-title text-base-content">Sign Up</h2>
          </div>

          {/* 1) Sign up with Google first */}
          <div className="mt-4">
            <GoogleButton
              label="Sign up with Google"
              onClick={handleGoogleSignup}
              style={{
                width: "100%",
                borderRadius: "0.5rem",
                overflow: "hidden",
              }}
            />
          </div>

          {/* 2) "OR" divider */}
          <div className="divider">or</div>

          {/* 3) Regular form fields */}
          <StepOne
            handleChange={handleChange}
            values={formData}
            onKeyPress={handleKeyPress}
          />

          {/* 4) "Sign Up" button */}
          <button
            onClick={handleSubmit}
            className="btn btn-primary w-full mt-4"
          >
            Sign Up
          </button>

          {/* 5) Already have an account link */}
          <div className="text-center mt-4">
            <span className="text-base-content text-sm">
              Already have an account?{' '}
              <button
                onClick={navigateToLogin}
                className="text-primary hover:underline focus:outline-none"
              >
                Log in
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
