import React, { useState } from "react";
import "../styles/style.css";

const StepOne = ({ nextStep, handleChange, values, onKeyPress }) => {
  const [error, setError] = useState("");

  const handleNextClick = () => {
    if (
      !values.firstName ||
      !values.lastName ||
      !values.email ||
      !values.password ||
      !values.aiConfirmation
    ) {
      setError("Please fill out all fields and accept the terms of service.");
    } else {
      setError("");
      nextStep();
    }
  };

  return (
    <>
      {error && <div className="alert alert-error mb-4">{error}</div>}
      <div className="flex flex-col gap-4">
        <input
          type="text"
          placeholder="First Name *"
          onChange={handleChange("firstName")}
          value={values.firstName}
          className="input input-bordered w-full text-base-content"
          required
          onKeyPress={onKeyPress}
        />
        <input
          type="text"
          placeholder="Last Name *"
          onChange={handleChange("lastName")}
          value={values.lastName}
          className="input input-bordered w-full text-base-content"
          required
          onKeyPress={onKeyPress}
        />
        <input
          type="email"
          placeholder="Email Address *"
          onChange={handleChange("email")}
          value={values.email}
          className="input input-bordered w-full text-base-content"
          required
          onKeyPress={onKeyPress}
        />
        <input
          type="password"
          placeholder="Password *"
          onChange={handleChange("password")}
          value={values.password}
          className="input input-bordered w-full text-base-content"
          required
          onKeyPress={onKeyPress}
        />
        <div className="form-control">
          <label className="label cursor-pointer flex items-center gap-2">
            <input
              type="checkbox"
              className="checkbox"
              onChange={(e) =>
                handleChange("aiConfirmation")({
                  target: { value: e.target.checked },
                })
              }
              checked={values.aiConfirmation || false}
            />
            <span className="label-text">
            I understand that Taxbot uses generative AI to produce answers and is prone to make mistakes. *
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export default StepOne;
