import React from 'react';
import { Link } from 'react-router-dom';
import { footerLinks } from '../HomeData/footerLinks';
import '../styles/tailwind-home.css';
import '../styles/global-home.css';

const Footer = () => {
  return (
    <footer className="pt-10 bg-white"> {/* Force light background and black text */}
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="mt-10 py-10 items-center justify-between sm:flex">
          <p className="text-black">© 2024 Taxbot. All rights reserved.</p>
          <ul className="flex flex-wrap items-center gap-4 mt-6 sm:text-sm sm:mt-0">
            {footerLinks.map((item) => (
              <li key={item.label}>
                <Link to={item.href} className="text-black"> {/* Force black text */}
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
